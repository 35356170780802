<template>
  <layout width="420">
    <template slot="header">
      Alarms
    </template>
    <template>
      <v-form>
        <v-combobox v-model="state.tags" label="Optional tags" multiple chips />
        <object-item-alarms
          v-model="state"
          :type="itemType"
          :object-item-type="objectItemTypes.monitoringItem"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { ref, onBeforeMount, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { schemasService } from '@/modules/common/api';
import { prepareObject } from '@/provider/utils';
import ObjectItemAlarms from '@/modules/objects/ui/object-card/general/ObjectItemAlarms.vue';
import Layout from '@/components/popup/PopupLayoutDefault';
import { monitoringItemService } from '@/modules/object-types/api';
import { defaultMonitoringAlarm } from '@/modules/objects/ui/object-card/general/item-helpers';
import { objectItemTypes } from '@/modules/objects/ui/object-card/general/item-helpers';

export default {
  name: 'MonitoringItemAlarmsDefaultEdit',
  components: {
    ObjectItemAlarms,
    Layout
  },
  props: {
    monitoringItemId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      tags: [],
      alarms: []
    });
    const monitoringItem = ref({});

    const handleSubmit = async () => {
      console.log('submit');
      await schemasService.updateValues(
        [
          {
            id: monitoringItem.value.stateAlarms.id,
            value: state.value.alarms
          },
          {
            id: monitoringItem.value.stateTags.id,
            value: state.value.tags
          }
        ],
        props.monitoringItemId
      );
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onBeforeMount(async () => {
      monitoringItem.value = prepareObject(
        await monitoringItemService.fetch(props.monitoringItemId)
      );
      const alarmCount = monitoringItem.value.commonAlertCount.value;
      for (let i = 0; i < alarmCount; i++) {
        state.value.alarms.push({
          ...defaultMonitoringAlarm(),
          ...(monitoringItem.value.stateAlarms?.value?.[i] || {})
        });
      }
      state.value.tags = monitoringItem.value.stateTags.value || [];
    });

    const itemType = computed(() => monitoringItem.value?.commonType?.value);

    return {
      state,
      loading,
      submit,
      itemType,
      objectItemTypes
    };
  }
};
</script>
