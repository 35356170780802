var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.value.alarms),function(alert,index){return [_c('form-subheader',{key:("header_" + index),attrs:{"title":("Alarm " + (index + 1))}}),_c('div',{key:("alert_" + index),staticClass:"alarm"},[_c('form-subheader',{staticClass:"text--secondary",attrs:{"title":"Alarm condition"}}),(_vm.objectItemType === _vm.objectItemTypes.monitoringItem)?_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-select',{staticClass:"mr-4",staticStyle:{"flex-basis":"60%"},attrs:{"value":alert.condition.operator,"items":_vm.operators,"clearable":""},on:{"input":function($event){return _vm.onInput(index, 'condition.operator', $event)}}}),_c('v-text-field',{staticStyle:{"flex-basis":"40%"},attrs:{"disabled":alert.condition.operator === _vm.DOES_NOT_CHANGE_OPERATOR,"value":alert.condition.value},on:{"input":function($event){return _vm.onInput(index, 'condition.value', $event)}}})],1):_c('div',[_c('v-select',{attrs:{"value":alert.condition.type,"label":"Alert type","items":Object.keys(_vm.GEO_ALARM_TYPES)},on:{"input":function($event){return _vm.onInput(index, 'condition.type', $event)}}}),(alert.condition.type === _vm.GEO_ALARM_TYPES.distance)?_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-select',{staticClass:"mr-4",attrs:{"value":alert.condition.operator,"items":_vm.operators},on:{"input":function($event){return _vm.onInput(index, 'condition.operator', $event)}}}),_c('v-text-field',{attrs:{"value":Number(alert.condition.value)},on:{"input":function($event){_vm.onInput(index, 'condition.value', Number($event))}}})],1):_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-select',{attrs:{"label":"Value","value":Boolean(alert.condition.value),"item-text":"name","item-value":"value","items":[
              { name: 'Inside', value: true },
              { name: 'Outside', value: false }
            ]},on:{"input":function($event){_vm.onInput(index, 'condition.value', Boolean($event))}}})],1)],1),_c('form-switcher',{attrs:{"title":"Timer and schedule","value":alert.isTimerAndSchedule},on:{"input":function($event){return _vm.onInput(index, 'isTimerAndSchedule', $event)}}}),(alert.isTimerAndSchedule)?[_c('form-subheader',{staticClass:"text--secondary",attrs:{"title":"Allowed time of stay in the state"}}),_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"value":alert.timeout.value},on:{"input":function($event){_vm.onInput(index, 'timeout.value', Number($event))}}}),_c('v-select',{attrs:{"value":alert.timeout.units,"items":_vm.timeUnits},on:{"input":function($event){return _vm.onInput(index, 'timeout.units', $event)}}})],1),_c('form-subheader',{staticClass:"text--secondary",attrs:{"title":"Alarm time interval"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('div',{staticClass:"flex-shrink-0",staticStyle:{"width":"60px"}},[_vm._v("From")]),_c('v-select',{staticClass:"mr-4",attrs:{"value":_vm.getTime(+alert.timeIntervalInMinutes.from, 'hours'),"items":_vm.hours,"suffix":"h"},on:{"input":function($event){_vm.onInput(
                  index,
                  'timeIntervalInMinutes.from',
                  $event * 60 + (+alert.timeIntervalInMinutes.from % 60)
                )}}}),_c('v-select',{attrs:{"value":_vm.getTime(+alert.timeIntervalInMinutes.from, 'minutes'),"items":_vm.minutes,"suffix":"min"},on:{"input":function($event){_vm.onInput(
                  index,
                  'timeIntervalInMinutes.from',
                  Math.floor(+alert.timeIntervalInMinutes.from / 60) * 60 +
                    +$event
                )}}})],1),_c('div',{staticClass:"d-flex flex-nowrap  align-center"},[_c('div',{staticClass:"flex-shrink-0",staticStyle:{"width":"60px"}},[_vm._v("To")]),_c('v-select',{staticClass:"mr-4",attrs:{"value":_vm.getTime(+alert.timeIntervalInMinutes.to, 'hours'),"items":_vm.hours,"suffix":"h"},on:{"input":function($event){_vm.onInput(
                  index,
                  'timeIntervalInMinutes.to',
                  +$event * 60 + (+alert.timeIntervalInMinutes.to % 60)
                )}}}),_c('v-select',{attrs:{"value":_vm.getTime(+alert.timeIntervalInMinutes.to, 'minutes'),"items":_vm.minutes,"suffix":"min"},on:{"input":function($event){_vm.onInput(
                  index,
                  'timeIntervalInMinutes.to',
                  Math.floor(alert.timeIntervalInMinutes.to / 60) * 60 +
                    +$event
                )}}})],1),_c('div',{staticClass:"d-flex flex-nowrap align-center my-2"},[_c('v-btn-toggle',{staticClass:"flex-grow-1",attrs:{"value":alert.weekDays,"multiple":""},on:{"change":function($event){return _vm.onInput(index, 'weekDays', $event)}}},_vm._l((_vm.weekDayNames),function(weekDay,idx){return _c('v-btn',{key:idx,staticClass:"flex-grow-1",attrs:{"color":alert.weekDays.includes(idx) ? 'primary white--text' : ''},domProps:{"textContent":_vm._s(weekDay)}})}),1)],1)])]:_vm._e(),_c('form-switcher',{attrs:{"title":"Dismiss by timer","value":alert.timer.dismiss},on:{"input":function($event){return _vm.onInput(index, 'timer.dismiss', $event)}}}),(alert.timer.dismiss)?_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"value":alert.timer.value},on:{"input":function($event){_vm.onInput(index, 'timer.value', Number($event))}}}),_c('v-select',{attrs:{"value":alert.timer.units,"items":_vm.timeUnits},on:{"input":function($event){return _vm.onInput(index, 'timer.units', $event)}}})],1):_vm._e()],2)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }